import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo.png';
import { Web3Button } from '@web3modal/react';
import { Link } from 'react-router-dom';
import '../components/StarryBackground.css'
import { useAccount, useContractRead, useContractWrite, useWaitForTransaction } from 'wagmi';
import BigNumber from 'bignumber.js';
import { parseEther } from 'viem';
import { erc721ABI } from 'wagmi'
import icon1 from '../assets/images/icon1.png'
import icon2 from '../assets/images/icon2.png'
import icon3 from '../assets/images/icon3.png'
import icon4 from '../assets/images/icon4.png'
import icon5 from '../assets/images/icon5.png'
import icon6 from '../assets/images/icon6.png'
import icon7 from '../assets/images/icon7.png'
import icon8 from '../assets/images/icon8.png'
import icon9 from '../assets/images/icon9.png'
import uniqueIcon from '../assets/images/unique.png'
import rareIcon from '../assets/images/rare.png'
import legendIcon from '../assets/images/legend.png'
import angelIcon from '../assets/images/angel.png'
import dragonIcon from '../assets/images/dragon.png'
import Cashemonstakingabi from '../ABI/CashemonStakingABI.json'
import Solcashabi from '../ABI/SolCashABI.json'
import CashemonHouseABI from '../ABI/CashemonHouseABI.json'
import CashemonPotionAbi from '../ABI/CashemonPotionABI.json'


function NFTSection({ data, setSelectedNftTokenId, selectedNftTokenId, setSelectedNftTokenIds, selectedNftTokenIds, inputValue, setInputValue, onDeposit, onWithdraw, activeDataSet, onWithdrawFunction1, onWithdrawFunction2, onWithdrawFunction3, onWithdrawFunction4, onWithdrawFunction5 }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const account = useAccount()


const BalanceRare = useContractRead({
  address: '0xd88Ee329a0D7157930920d36B5419911874Fc308',
  abi: CashemonHouseABI,
  functionName: 'balanceOf',
  args: [account.address, "1"],
  watch: true,
})

const BalanceLegend = useContractRead({
  address: '0xd88Ee329a0D7157930920d36B5419911874Fc308',
  abi: CashemonHouseABI,
  functionName: 'balanceOf',
  args: [account.address, "2"],
  watch: true,
})

const BalanceUnique = useContractRead({
  address: '0xd88Ee329a0D7157930920d36B5419911874Fc308',
  abi: CashemonHouseABI,
  functionName: 'balanceOf',
  args: [account.address, "0"],
  watch: true,
})


const BalanceAngel = useContractRead({
  address: '0xB568288636B54077B076A9bd326b4efECD801C6D',
  abi: CashemonPotionAbi,
  functionName: 'balanceOf',
  args: [account.address, "3"],
  watch: true,
})

const BalanceDragon = useContractRead({
  address: '0xB568288636B54077B076A9bd326b4efECD801C6D',
  abi: CashemonPotionAbi,
  functionName: 'balanceOf',
  args: [account.address, "5"],
  watch: true,
})




  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToShow = data.slice(startIndex, endIndex);

  let itemsToRender;

  switch (activeDataSet) {
    case 'nfts':
      itemsToRender = data.slice(startIndex, endIndex).map(nft => (
        
          <div 
          key={nft.token_id}
          className="nft-item"
          onClick={() => {
              if (activeDataSet === 'nfts') {
                  setSelectedNftTokenIds(prevIds => {
                      if (prevIds.includes(nft.token_id)) {
                          return prevIds.filter(id => id !== nft.token_id);
                      } else {
                          return [...prevIds, nft.token_id];
                      }
                  });
              } else {
                  setSelectedNftTokenId(nft.token_id);
              }
          }}
          style={{ border: (activeDataSet === 'nfts' ? selectedNftTokenIds.includes(nft.token_id) : selectedNftTokenId === nft.token_id) ? '3px solid #fd8407' : 'none' }}
      >
              <img src={`https://dweb.link/ipfs/${nft.image_uri}`} alt={nft.name} width="150" height="150" />
              <div>{nft.name}</div>
              <div>{nft.description}</div>
              <div>ID: {nft.token_id}</div>
              <div style={{fontSize: '22px'}}>Your Balance: {nft.amount}</div>
          </div>

      ));
      break;

    case 'nfthouses':
      itemsToRender = (
        <>
          <div 
                  className="nft-item"
                  onClick={() => {
                     
                          setSelectedNftTokenId(0);
                      }
                  }
                  style={{ border: (selectedNftTokenId === 0) ? '3px solid #fd8407' : 'none' }}
              >
                      <img src={uniqueIcon} width="150" height="150"/>
                      <div>Unique Cashemon House</div>
                      <div>Cashemon Staking House for the Unique Cashemon</div>
                      <div>ID: 0</div>
                      <div style={{fontSize: '22px'}}>Your Residents: {BalanceUnique.data ? BalanceUnique.data.toString() : '0'}</div>
                  </div>
                  <div 
                  className="nft-item"
                  onClick={() => {
                     
                          setSelectedNftTokenId(1);
                      }
                  }
                  style={{ border: (selectedNftTokenId === 1) ? '3px solid #fd8407' : 'none' }}
              >
                      <img src={rareIcon} width="150" height="150"/>
                      <div>Unique Cashemon House</div>
                      <div>Cashemon Staking House for the Rare Cashemon</div>
                      <div>ID: 1</div>
                      <div style={{fontSize: '22px'}}>Your Residents: {BalanceRare.data ? BalanceRare.data.toString() : '0'}</div>
                  </div>
                  <div 
                  className="nft-item"
                  onClick={() => {
                     
                          setSelectedNftTokenId(2);
                      }
                  }
                  style={{ border: (selectedNftTokenId === 2) ? '3px solid #fd8407' : 'none' }}
              >
                      <img src={legendIcon} width="150" height="150"/>
                      <div>Unique Cashemon House</div>
                      <div>Cashemon Staking House for the Legendary Cashemons</div>
                      <div>ID: 2</div>
                      <div style={{fontSize: '22px'}}>Your Residents: {BalanceLegend.data ? BalanceLegend.data.toString() : '0'}</div>
                  </div>
        </>
      );
      break;

    case 'nftpotions':
      itemsToRender = (
        <>
          <div 
                  className="nft-item"
                  onClick={() => {
                     
                          setSelectedNftTokenId(3);
                      }
                  }
                  style={{ border: ( selectedNftTokenId === 3) ? '3px solid #fd8407' : 'none' }}
              >
                      <img src={angelIcon} width="150" height="150"/>
                      <div>Angel Blood</div>
                      <div>A very rare bottle filled with AngelBlood</div>
                      <div>ID: 3</div>
                      <div style={{fontSize: '22px'}}>Your Balance: {BalanceAngel.data ? BalanceAngel.data.toString() : '0'}</div>
                  </div>
                  <div 
                  className="nft-item"
                  onClick={() => {
                     
                          setSelectedNftTokenId(5);
                      }
                  }
                  style={{ border: (selectedNftTokenId === 5) ? '3px solid #fd8407' : 'none' }}
              >
                      <img src={dragonIcon} width="150" height="150"/>
                      <div>Dragon Blood</div>
                      <div>A bottle full of rare dragon blood</div>
                      <div>ID: 5</div>
                      <div style={{fontSize: '22px'}}>Your Balance: {BalanceDragon.data ? BalanceDragon.data.toString() : '0'}</div>
                  </div>
        </>
      );
      break;

    default:
      itemsToRender = <></>;
  }

  return (
      <div >
          <div className="nft-container">
            {itemsToRender}
            </div>
            <div>  
              <div className="pagination-controls">
                <span>Page {currentPage} of {totalPages}</span>
                <div className='arrows' style={{justifyContent: 'center'}}>
                  <div class="containerbutton">
                    <button class="buttonleft"  onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}>Prev</button>
                    <span class="arrowleft first">
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path transform="scale(-1,1) translate(-24,0)" d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                      </svg>
                    </span>
                    <span class="arrowleft second">
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path transform="scale(-1,1) translate(-24,0)" d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                      </svg>
                    </span>
                    <span class="arrowleft third">
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path transform="scale(-1,1) translate(-24,0)" d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="containerbutton">
                    <button class="button" onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))}>Next</button>
                      <span class="arrow first">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                        </svg>
                      </span>
                      <span class="arrow second">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                        </svg>
                      </span>
                      <span class="arrow third">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                        </svg>
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div>

            <input 
              className="input" 
              type="text" 
              placeholder="Enter Amount..." 
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />

            </div>
            {activeDataSet === 'nfts' && (
            <div>
                <button className="action-btn" onClick={onDeposit}><span>Deposit NFT</span></button>
                <button className="action-btn" onClick={onWithdraw}><span>Withdraw NFT</span></button>
            </div>
          )}

          {activeDataSet === 'nfthouses' && (
            <div>
                <button className="action-btn" onClick={onDeposit}><span>Deposit NFT</span></button>
                <div>
                  <button className="action-btn" onClick={onWithdrawFunction3}><span>Withdraw Uniques</span></button>
                  <button className="action-btn" onClick={onWithdrawFunction1}><span>Withdraw Rares</span></button>
                  <button className="action-btn" onClick={onWithdrawFunction2}><span>Withdraw Legendaries</span></button>
                </div>
            </div>
          )}

          {activeDataSet === 'nftpotions' && (
            <div>
                <button className="action-btn" onClick={onDeposit}><span>Deposit NFT</span></button>
                <div>
                  <button className="action-btn" onClick={onWithdrawFunction4}><span>Withdraw Angelblood</span></button>
                  <button className="action-btn" onClick={onWithdrawFunction5}><span>Withdraw Dragonblood</span></button>
                </div>
            </div>
          )}
      </div>
  );
}


function DashboardNFT() {
    const account = useAccount()
    const [userInput, setUserInput] = useState("");
    const [txHash, setTxHash] = useState(null);
    const [selectedNftTokenId, setSelectedNftTokenId] = useState(null);
    const [txHashNFT, setTxHashNFT] = useState(null);
    const [status, setStatus] = useState(null);
    const [status2, setStatus2] = useState(null);
    const [activeDataSet, setActiveDataSet] = useState('nfts'); // possible values: 'nfts', 'nfthouses', 'nftpotions'
    const [nftData, setNftData] = useState({ nfts: [], nfthouses: [], nftpotions: [] });
    const [selectedNftTokenIds, setSelectedNftTokenIds] = useState([]); 
    const [inputValue, setInputValue] = useState("");
    const [solCashPrice, setsolCashPrice] = useState("");
    const [data, setData] = useState({});

  const TotalStakers = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getTotalUsers',
    watch: true,
  })

  const totalStakersString = TotalStakers?.data?.toString() ;
  
  const TotalStakedToken = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getTotalStakedTokens',
    watch: true,
  })
  
  const totalStakedBigNumber = new BigNumber(TotalStakedToken?.data?.toString() || '0');
  const humanReadableNumber = totalStakedBigNumber.dividedBy(new BigNumber('1e18'));

  const totalstakedvalue = humanReadableNumber * solCashPrice
  const formattedtotalstakedvalue = totalstakedvalue.toFixed(2)

  const formattedStakedAmount = Intl.NumberFormat().format(humanReadableNumber.toFixed(0));

  const StakedUserBalance = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getUser',
    args: [account.address],
    watch: true,
  })
  
  const totalUserStakedBigNumber = new BigNumber(StakedUserBalance?.data?.stakeAmount?.toString() || '0');
  const humanReadableNumberUser = totalUserStakedBigNumber.dividedBy(new BigNumber('1e18'));

  const stakedbalancevalue = humanReadableNumberUser * solCashPrice
  const formattedbalancevalue = stakedbalancevalue.toFixed(2)


  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const lastStakedTimestampBigInt = StakedUserBalance?.data?.lastStakeTime;
  
    const calculateRemainingTime = () => {
      if (lastStakedTimestampBigInt !== undefined) {
        const lastStakedTimestamp = parseInt(lastStakedTimestampBigInt.toString(), 10);

  
        // Convert the last staked timestamp to milliseconds
        const lastStakedTimestampMillis = lastStakedTimestamp * 1000;
  
        const lastStakedTimestampBN = new BigNumber(lastStakedTimestampMillis.toString());
  
        const millisecondsIn60Days = 60 * 24 * 60 * 60 * 1000;
        const expirationTimestamp = lastStakedTimestampBN.plus(millisecondsIn60Days);
  
        const now = Date.now();
        const remaining = expirationTimestamp.minus(now);
  
  
        // Check if the countdown has expired
        if (remaining.isNegative()) {

          setTimeRemaining(0); // Countdown expired
        } else {
          setTimeRemaining(remaining.toNumber());
        }
      } else {
        // Handle the case where lastStakedTimestampBigInt is not available
        // You can set an initial state or perform other actions here
      }
    };
  
    // Initial countdown update
    calculateRemainingTime();
  
    // Update the countdown every second
    const countdownInterval = setInterval(calculateRemainingTime, 1000);
  
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(countdownInterval);
    };
  }, [StakedUserBalance?.data?.lastStakeTime]);
  
  
  // Function to format the remaining time as a string
  const formatTime = (milliseconds) => {
    const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
    const hours = Math.floor((milliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
  
    return `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`;
  };
  
  





  const formattedUserAmount = Intl.NumberFormat().format(humanReadableNumberUser.toFixed(0));

  const UserBaseBalance = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getUserBaseAmounts',
    args: [account.address],
    watch: true,
  })


  const basic = new BigNumber(UserBaseBalance?.data?.basic?.toString() || '0');
  const formattedbasic = basic.dividedBy(new BigNumber('1e18'))


  const legendary = new BigNumber(UserBaseBalance?.data?.legendary?.toString() || '0');
  const formattedlegendary = legendary.dividedBy(new BigNumber('1e18'))


  const rare = new BigNumber(UserBaseBalance?.data?.rare?.toString() || '0');
  const formattedrare = rare.dividedBy(new BigNumber('1e18'))


  const unique = new BigNumber(UserBaseBalance?.data?.unique?.toString() || '0');
  const formattedunique = unique.dividedBy(new BigNumber('1e18'))
 

  const totalValue = parseFloat(formattedbasic) + parseFloat(formattedlegendary) + parseFloat(formattedrare) + parseFloat(formattedunique);

  const UserBaseAmount = Intl.NumberFormat().format(totalValue.toFixed(0));

  const totalUser = parseFloat(totalValue) + parseFloat(humanReadableNumberUser)

  const totalstakeduservalue = totalUser * solCashPrice
  const formattedtotalstakeduservalue = totalstakeduservalue.toFixed(2)

  const totalstakeduser = Intl.NumberFormat().format(totalUser.toFixed(0));



  const userestimatedreward = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getUserEstimatedRewards',
    args: [account.address],
    watch: true,
  })
  
  const totalUserRewardBigNumber = new BigNumber(userestimatedreward?.data?.toString() || '0');
  const humanReadableNumberReward = totalUserRewardBigNumber.dividedBy(new BigNumber('1e18'));

  const userrewardvalue = humanReadableNumberReward * solCashPrice
  const formattedrewardvalue = userrewardvalue.toFixed(2)

  const formattedRewardAmount = Intl.NumberFormat().format(humanReadableNumberReward.toFixed(0));

  const userAPY = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getUserAPY',
    args: [account.address],
    watch: true,
  })


  const totalUserAPY = new BigNumber(userAPY?.data?.totalAPY.toString() || '0');
  const modifiedAPY = totalUserAPY.div(100).plus(10).toFixed(2);
 
  const AngelBloodAmount = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getStakedAngelBloodAmount',
    args: [account.address],
    watch: true,
  })

  const DragonBloodAmount = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getStakedDragonBloodAmount',
    args: [account.address],
    watch: true,
  })

  const BasicCashemonAmount = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getStakedBasicCashemonAmount',
    args: [account.address],
    watch: true,
  })

  const LegendaryCashemonAmount = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getStakedLegendaryCashemonAmount',
    args: [account.address],
    watch: true,
  })

  const RareCashemonAmount = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getStakedRareCashemonAmount',
    args: [account.address],
    watch: true,
  })

  const UniqueCashemonAmount = useContractRead({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'getStakedUniqueCashemonAmount',
    args: [account.address],
    watch: true,
  })

  const isApprovedCashemon = useContractRead({
    address: '0x95386f0310C49fD9EcECddCb53eeb7126F68278F',
    abi: erc721ABI,
    functionName: 'isApprovedForAll',
    args: [account.address, "0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1"],
    watch: true,
  })



  const isApprovedHouse = useContractRead({
    address: '0xd88Ee329a0D7157930920d36B5419911874Fc308',
    abi: erc721ABI,
    functionName: 'isApprovedForAll',
    args: [account.address, "0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1"],
    watch: true,
  })

  const isApprovedPotion = useContractRead({
    address: '0xB568288636B54077B076A9bd326b4efECD801C6D',
    abi: erc721ABI,
    functionName: 'isApprovedForAll',
    args: [account.address, "0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1"],
    watch: true,
  })


  const { isLoading: approveLoad, writeAsync: approve } = useContractWrite({
    address: '0x7B86f5ca09Dc00502E342b0FeF5117E1c32222Ce',
    abi: Solcashabi,
    functionName: 'approve',
    args: ["0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1", parseEther(userInput)],
    onSuccess: async (data) => {
      setTxHash(data.hash);  
      }
  })

  const { isLoading: StakeLoad, isSuccess: StakeSuccess, writeAsync: stake } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'stake',
    args: [parseEther(userInput)],
    onSuccess: async () => {
      setStatus2('StakeSuccess')
      setTimeout(() => {
        setStatus2('')
    }, 10000); 
      }
  })

  const { waitForConfirmation, isLoading: waitconf, isSuccess: confsuccess} = useWaitForTransaction({
    confirmations: 3,
    hash: txHash,
    onSuccess: async () => {
      setStatus2('confsuccess')
       await stake();
       setTimeout(() => {
        setStatus2('')
    }, 2000);  
      
    },
  });

  const approveandstake = async () => {
    try {
     await approve();
    
     await waitForConfirmation
      
     } catch (error) {
        console.error('Buy error:', error);
      }
  };
      
  const { isLoading: unstakeLoad, isSuccess: unstakeSuccess, write: unstake } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'unstake',
    args: [parseEther(userInput)],
    onSuccess: async () => {
      setStatus2('unstakeSuccess')
      setTimeout(() => {
        setStatus2('')
    }, 10000); 
      }
  })

  const { isLoading: rewardLoad, isSuccess: rewardSuccess, write: claimreward } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'claimReward',
  })

  const { isLoading: approveNFTload, writeAsync: approvenft } = useContractWrite({
    address: '0x95386f0310C49fD9EcECddCb53eeb7126F68278F',
    abi: erc721ABI,
    functionName: 'setApprovalForAll',
    args: ["0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1", true],
    onSuccess: async (data) => {
      setTxHashNFT(data.hash);  
      }
  })

  const { isLoading: depositLoad, isSuccess: depositSuccess, writeAsync: depositnft } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'depositCashemonBasic',
    args: [selectedNftTokenIds],
    onSuccess: async () => {
      setStatus('depositSuccess')
      setTimeout(() => {
        fetchNFTData();
        setStatus('')
    }, 15000); 
      }
  })

  const { waitForNFTConfirmation, isLoading: waitNFTconf, isSuccess: NFTconfsuccess } = useWaitForTransaction({
    confirmations: 3,
    hash: txHashNFT,
    onSuccess: async () => {
      setStatus('NFTconfsuccess')
      setTimeout(() => {
        setStatus('')
    }, 2000);
        if (selectedNftTokenId !== undefined && selectedNftTokenId !== null) {
            
            const tokenId = Number(selectedNftTokenId); 

            if (tokenId === 0) {
                await depositUniques();
            } else if (tokenId === 1) {
                await depositRare();
            } else if (tokenId === 2) {
                await depositLegend();
            } else if (tokenId === 3 || tokenId === 5) { 
                await depositpotion();
            } else {
                console.log("Invalid TokenID");
            }
        } else {
            await depositnft();
        }
    },
});

  const { isLoading: withdrawLoad, isSuccess: withdrawSuccess, writeAsync: withdrawNFT } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'withdrawCashemonBasic',
    args: [inputValue],
    onSuccess: async () => {
      setStatus('withdrawSuccess')
      setTimeout(() => {
        fetchNFTData();
        setStatus('')
    }, 15000); 
      }
  })

  const { isLoading: approveHouseload, writeAsync: approvehouse } = useContractWrite({
    address: '0xd88Ee329a0D7157930920d36B5419911874Fc308',
    abi: CashemonHouseABI,
    functionName: 'setApprovalForAll',
    args: ["0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1", true],
    onSuccess: async (data) => {
      setTxHashNFT(data.hash);  
      }
  })

  const { isLoading: depositLegendload, isSuccess: depositLegendSuccess, writeAsync: depositLegend } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'depositCashemonLegendaries',
    args: [selectedNftTokenId, inputValue],
    onSuccess: async () => {
      setStatus('depositLegendSuccess')
      setTimeout(() => {

        setStatus('')
    }, 15000); 
      }
  })

  const { isLoading: depositRareload, isSuccess: depositRareSuccess, writeAsync: depositRare } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'depositCashemonRares',
    args: [selectedNftTokenId, inputValue],
    onSuccess: async () => {
      setStatus('depositRareSuccess')
      setTimeout(() => {

        setStatus('')
    }, 15000); 
      }
  })

  const { isLoading: depositUniquesload, isSuccess: depositUniquesSuccess, writeAsync: depositUniques } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'depositCashemonUniques',
    args: [selectedNftTokenId, inputValue],
    onSuccess: async () => {
      setStatus('depositUniqueSuccess')
      setTimeout(() => {

        setStatus('')
    }, 15000); 
      }
  })


  const { isLoading: withdrawRareLoad, isSuccess: withdrawRareSuccess, writeAsync: withdrawRare } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'withdrawCashemonRares',
    args: [inputValue],
    onSuccess: async () => {
      setStatus('withdrawRareSuccess')
      setTimeout(() => {

        setStatus('')
    }, 15000); 
      }
  })

  const { isLoading: withdrawLegendLoad, isSuccess: withdrawLegendSuccess, writeAsync: withdrawLegend } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'withdrawCashemonLegendaries',
    args: [inputValue],
    onSuccess: async () => {
      setStatus('withdrawLegendSuccess')
      setTimeout(() => {

        setStatus('')
    }, 15000); 
      }
  })

  const { isLoading: withdrawUniqueLoad, isSuccess: withdrawUniqueSuccess, writeAsync: withdrawUnique } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'withdrawCashemonUniques',
    args: [inputValue],
    onSuccess: async () => {
      setStatus('withdrawUniqueSuccess')
      setTimeout(() => {

        setStatus('')
    }, 15000); 
      }
  })

  const { isLoading: approvePotionload, writeAsync: approvepotion } = useContractWrite({
    address: '0xB568288636B54077B076A9bd326b4efECD801C6D',
    abi: CashemonPotionAbi,
    functionName: 'setApprovalForAll',
    args: ["0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1", true],
    onSuccess: async (data) => {
      setTxHashNFT(data.hash);  
      }
  })


  const { isLoading: depositPotionload, isSuccess: depositPotionSuccess, writeAsync: depositpotion } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'depositPotions',
    args: [selectedNftTokenId, inputValue],
    onSuccess: async () => {
      setStatus('depositPotionSuccess')
      setTimeout(() => {

        setStatus('')
    }, 15000); 
      }
  })

  const { isLoading: withdrawAngelLoad, isSuccess: withdrawAngelSuccess, writeAsync: withdrawAngel } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'withdrawPotions',
    args: [ inputValue , 3],
    onSuccess: async () => {
      setStatus('withdrawAngelSuccess')
      setTimeout(() => {

        setStatus('')
    }, 15000);  
      }
  })

  const { isLoading: withdrawDragonLoad, isSuccess: withdrawDragonSuccess, writeAsync: withdrawDragon } = useContractWrite({
    address: '0x2162bd2A42e474edC037F1D36055Bec86B2bC2E1',
    abi: Cashemonstakingabi,
    functionName: 'withdrawPotions',
    args: [ inputValue , 5],
    onSuccess: async () => {
      setStatus('withdrawDragonSuccess')
      setTimeout(() => {

        setStatus('')
    }, 15000); 
      }
  })

  const walletAddress = account.address;


  const fetchNFTData = () => {
    fetch(`/nft-balance/${walletAddress}`)
        .then(response => response.json())
        .then(data => {
            setNftData(prevData => ({ ...prevData, nfts: data.data.content }));
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
  }

  useEffect(() => {
    fetchNFTData();
}, [walletAddress]);


  useEffect(() => {
    fetch(`/getPriceData`)
    .then(response => response.json())
    .then(data => {

      if (data && data.data && data.data.getTokenPrices && data.data.getTokenPrices.length > 0) {
        setsolCashPrice(data.data.getTokenPrices[0].priceUsd);
    } else {
        console.error('Invalid data structure:', data);
    }
    
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);  

  useEffect(() => {
    // Define the endpoint URLs
    const endpoints = [
      '/fetchDataCashemons',
      '/fetchDataHouses',
      '/fetchDataPotions',
    ];

    // Create an array of promises for each endpoint
    const requests = endpoints.map((endpoint) =>
      fetch(endpoint).then((response) => response.json())
    );

    // Use Promise.all to fetch data from all endpoints in parallel
    Promise.all(requests)
      .then((responses) => {
        // Combine the results into a single object
        const combinedData = {
          Cashemons: responses[0],
          Houses: responses[1],
          Potions: responses[2],
        };

        // Set the combined data in the state
        setData(combinedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


    const [dataBlock] = useState(
        {  
            heading: 'CASHEMON NFT STAKING DASHBOARD',   
        }
    )


    const calculateTotalAmount = (data) => {
      // Define the multipliers
      const multipliers = {
        Cashemon: 50000,
        'Unique Cashemon House': 150000,
        'Rare Cashemon House': 250000,
        'Legendary Cashemon House': 500000,
      };
    
      // Initialize the total amount
      let totalAmount = 0;
    
      // Iterate through the data and update the total amount
      for (const category in data) {
        totalAmount += data[category].reduce((acc, item) => {
          // Skip items with certain names
          if (item.name === 'AngelBlood' || item.name === 'Dragon Blood') {
            return acc;
          }
    
          const multiplier = multipliers[item.name] || 1; // Default to 1 if no multiplier is found
          const itemAmount = parseFloat(item.amount);
          const multipliedAmount = itemAmount * multiplier;
    
          return acc + multipliedAmount;
        }, 0);
      }
    
      // Format the total amount
      const formattedTotalAmount = new Intl.NumberFormat().format(totalAmount);
    
      return formattedTotalAmount;
    };
    
    // Call the calculateTotalAmount function to get the total amount
    const totalAmountFormatted = calculateTotalAmount(data);
    const totalAmountWithoutDots = totalAmountFormatted.replace(/\./g, '');
    const multipliedValue = parseFloat(totalAmountWithoutDots) * parseFloat(solCashPrice);
    const displaytotalvalue = multipliedValue.toFixed(2)
    

    // 'totalAmountFormatted' now contains the correct total sum of all the multiplied amounts.
    
    
    
    

    const handleDepositForNfts = async () => {
      try {

        if (!isApprovedCashemon.data) {
          await approvenft();
          await waitForNFTConfirmation;
        } else {

          await depositnft();
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

  const handleWithdrawForNfts = async () => {
    try {
      await withdrawNFT();
       
      } catch (error) {
         console.error('Buy error:', error);
       }
  };

  const handleDepositForNftHouses = async () => {
    
    try {
      if (!isApprovedHouse.data) {
      await approvehouse();
     
      await waitForNFTConfirmation
      } else {

        const tokenId = Number(selectedNftTokenId);

        if (tokenId === 0) {
          await depositUniques();
      } else if (tokenId === 1) {
          await depositRare();
      } else if (tokenId === 2) {
          await depositLegend();
      } 
      }
      } catch (error) {
         console.error('Buy error:', error);
       }
  };

  const handleDepositForNftPotions = async () => {
    try {
      if (!isApprovedPotion.data) {
      await approvepotion();
     
      await waitForNFTConfirmation
      } else{
        await depositpotion();
      }
      } catch (error) {
         console.error('Buy error:', error);
       }
  };

  useEffect(() => {
    if (approveNFTload) setStatus('approveNFTload');
    else if (approveHouseload) setStatus('approveHouseload');
    else if (approvePotionload) setStatus('approvePotionload');
    else if (waitNFTconf) setStatus('waitNFTconf');
    else if (depositLoad) setStatus('depositLoad');
    else if (depositRareload) setStatus('depositRareload');
    else if (depositUniquesload) setStatus('depositUniquesload');
    else if (depositPotionload) setStatus('depositPotionload');
    else if (depositLegendload) setStatus('depositLegendload');
    else if (withdrawLoad) setStatus('withdrawLoad');
    else if (withdrawLegendLoad) setStatus('withdrawLegendLoad');
    else if (withdrawRareLoad) setStatus('withdrawRareLoad');
    else if (withdrawUniqueLoad) setStatus('withdrawUniqueLoad');
    else if (withdrawAngelLoad) setStatus('withdrawAngelLoad');
    else if (withdrawDragonLoad) setStatus('withdrawDragonLoad');
}, [
  approveNFTload, 
  approveHouseload,
  approvePotionload,
  waitNFTconf,
  depositLoad,
  depositRareload,
  depositUniquesload,
  depositPotionload,
  depositLegendload,
  withdrawLoad,
  withdrawLegendLoad,
  withdrawRareLoad,
  withdrawUniqueLoad,
  withdrawAngelLoad,
  withdrawDragonLoad,
]);

  const getStatusMessage = () => {
    switch (status) {
      case 'approveNFTload':
        return "Approve running.";
      case 'approveHouseload':
        return "Approve running.";
      case 'approvePotionload':
        return "Approve running.";
      case 'waitNFTconf':
        return "Waiting for blockchain confirmation...";
      case 'NFTconfsuccess':
        return "Confirmed.";
      case 'depositLoad':
        return "Waiting for deposit confirmation.";
      case 'depositRareload':
        return "Waiting for deposit confirmation.";
      case 'depositUniquesload':
        return "Waiting for deposit confirmation.";
      case 'depositPotionload':
        return "Waiting for deposit confirmation.";
      case 'depositLegendload':
        return "Waiting for deposit confirmation.";
      case 'depositSuccess':
        return "Successful deposited your NFT";
      case 'depositLegendSuccess':
        return "Successful deposited your NFT";
      case 'depositRareSuccess':
        return "Successful deposited your NFT";
      case 'depositUniquesSuccess':
          return "Successful deposited your NFT";
      case 'depositPotionSuccess':
        return "Successful deposited your NFT";  
      case 'withdrawLoad':
        return "Waiting for confirmation.";
      case 'withdrawLegendLoad':
        return "Waiting for confirmation.";
      case 'withdrawRareLoad':
        return "Waiting for confirmation.";
      case 'withdrawUniqueLoad':
        return "Waiting for confirmation.";
      case 'withdrawAngelLoad':
        return "Waiting for confirmation.";
      case 'withdrawDragonLoad':
        return "Waiting for confirmation.";
      case 'withdrawSuccess':
        return "Successful withdrawn your NFT";
      case 'withdrawLegendSuccess':
        return "Successful withdrawn your NFT";
      case 'withdrawRareSuccess':
        return "Successful withdrawn your NFT";
      case 'withdrawUniqueSuccess':
        return "Successful withdrawn your NFT";
      case 'withdrawAngelSuccess':
        return "Successful withdrawn your NFT";
      case 'withdrawDragonSuccess':
        return "Successful withdrawn your NFT";
      default:
        return null;
    }
  };

  useEffect(() => {
    if (approveLoad) setStatus2('approveLoad');
    else if (waitconf) setStatus2('waitconf');
    else if (StakeLoad) setStatus2('StakeLoad');
    else if (unstakeLoad) setStatus2('unstakeLoad');
  }, [
    approveLoad, 
    waitconf,
    confsuccess,
    StakeLoad,
    StakeSuccess,
    unstakeLoad,
    unstakeSuccess,
  ]);

  const getStatusMessage2 = () => {
    switch (status2) {
      case 'approveLoad':
        return "Approve running.";
      case 'waitconf':
        return "Waiting for blockchain confirmation...";
      case 'confsuccess':
        return "Confirmed.";
      case 'StakeLoad':
        return "Waiting for staking confirmation.";
      case 'StakeSuccess':
        return "Successful staked your tokens";
      case 'unstakeLoad':
        return "Waiting for confirmation.";
      case 'unstakeSuccess':
        return "Successful unstaked your tokens";
      default:
        return null;
    }
  };


    return (
        <>
        <header id="header_main" className='header is-fixed dashboardhead'>
        <div className="container big">
            <div className="row">
                <div className="col-12">
                    <div className="header__body dashboardhead">
                        <div className="header__logo dashboardhead">
                            <Link to="/">
                                <img id="site-logo" src={logo} alt='cyfonii' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section className="dashboard">
  
  <div className="container">
    <div className='col-12 dashhead'>
      <div className="block-text center">
        <h3 className="heading pd">{dataBlock.heading}</h3>
      </div>
      <div className="dashboardhead">
                        <Web3Button /> 
                        </div>
    </div>
    <div className="row">
      

      <div className="col-12 col-md-12">
        <div className="speciality-box own2">
          <h6 className="title"><span>Staking Data Cashemon Pool</span></h6>
          <div className="row">
          <div className="col-12 col-md-4">
              <div className="speciality-box owninside2">
                <div className='icondash'>
                  <img src={icon1}></img>
                </div>
                <h6 className="title"><span>Base APY:</span></h6>
                <p>10%</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="speciality-box owninside2">
                <div className='icondash'>
                  <img src={icon2}></img>
                </div>
                <h6 className="title"><span>MAX POOL SIZE:</span></h6>
                <p>300,000,000 $SolCash</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="speciality-box owninside2">
                <div className='icondash'>
                  <img src={icon3}></img>
                </div>
                <h6 className="title"><span>LOCK TIME:</span></h6>
                <p>60 Days</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="speciality-box owninside2" style={{height: '90%', flexDirection: 'column'}}>
                <div className='icondash'>
                  <img src={icon4}></img>
                </div>
                <h6 className="title"><span>TOTAL STAKERS:</span></h6>
                <p>{totalStakersString}</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="speciality-box owninside2" style={{height: '90%', flexDirection: 'column'}}>
                <div className='icondash'>
                  <img src={icon5}></img>
                </div>
                <h6 className="title"><span>TOTAL NFT's STAKED:</span></h6>
                <ul>
                  {Object.values(data).map((items) => (
                    items.map((item) => (
                    <li key={item.name}>
                      {item.name}: {item.amount}
                    </li>
                    ))
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="speciality-box owninside2" style={{height: '90%', flexDirection: 'column'}}>
                <div className='icondash'>
                  <img src={icon6}></img>
                </div>
                <h6 className="title"><span>EARLY WITHDRAW FEE:</span></h6>
                <p>15%</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="speciality-box owninside2" style={{height: '90%', flexDirection: 'column'}}>
                <div className='icondash'>
                  <img src={icon2}></img>
                </div>
                <h6 className="title"><span>TOTAL TOKENS STAKED:</span></h6>
                <p>{formattedStakedAmount} $SolCash</p>
                <p>(Current Value: {formattedtotalstakedvalue} $)</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="speciality-box owninside2" style={{height: '90%', flexDirection: 'column'}}>
                <div className='icondash'>
                  <img src={icon3}></img>
                </div>
                <h6 className="title"><span>TOTAL ADDED SOLCASH:</span></h6>
                <p>{totalAmountFormatted} $SOLCASH</p>
                <p>(Current Value: {displaytotalvalue} $)</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="speciality-box owninside2" style={{height: '90%', flexDirection: 'column'}}>
                <div className='icondash'>
                  <img src={icon1}></img>
                </div>
                <h6 className="title"><span>EARLY WITHDRAW FEE EXPIRES IN:</span></h6>
                <p>{timeRemaining !== null ? formatTime(timeRemaining) : (timeRemaining === 0 ? 'Expired' : 'Loading...')}</p>

              </div>
            </div>
            <div className="col-12 col-md-12">
              <div className="speciality-box owninside2">
                <h6 className="title"><span>🔔 Important Notice 🔔</span></h6>
                <p>

                Before withdrawing or depositing any NFT, please ensure you've claimed all your rewards. Failure to do so may result in loss of unclaimed rewards. Always prioritize your rewards – they're valuable!
                <br></br><br></br>
                Stay safe and happy collecting! 🎉
                </p>
                <div>
              
                <button className="action-btn" ><a href="https://cashverse.io/buycashemon"><span>Buy Cashemon</span></a></button>
                <button className="action-btn" ><a href="https://poocoin.app/embed-swap?outputCurrency=0x7b86f5ca09dc00502e342b0fef5117e1c32222ce" ><span>Buy Solcash</span></a></button>
              </div>
              </div>
            </div>   

    <div className="col-12 col-md-12">
      <div className="speciality-box owninside2">
        <div className="menu">
          <button className='Btn' onClick={() => setActiveDataSet('nfts')}>Cashemons</button>
          <button className='Btn2' onClick={() => setActiveDataSet('nfthouses')}>Houses</button>
          <button className='Btn' onClick={() => setActiveDataSet('nftpotions')}>Potions</button>
        </div>
          <h6 className="title"><span>Deposit your NFT:</span></h6>
            <p>Choose below which NFT should be staked</p>

                {/* Render the active dataset */}
                <NFTSection 
                     
                     data={nftData[activeDataSet]} 
                     setSelectedNftTokenId={setSelectedNftTokenId} 
                     selectedNftTokenId={selectedNftTokenId}
                     setSelectedNftTokenIds={setSelectedNftTokenIds}  
                     selectedNftTokenIds={selectedNftTokenIds} 
                     setInputValue={setInputValue}
                     inputValue={inputValue}
                     activeDataSet={activeDataSet}
                     onDeposit={
                      activeDataSet === 'nfts' ? handleDepositForNfts :
                      activeDataSet === 'nfthouses' ? handleDepositForNftHouses :
                      handleDepositForNftPotions
                    }
                    onWithdraw={handleWithdrawForNfts}
                    onWithdrawFunction1={withdrawRare}
                    onWithdrawFunction2={withdrawLegend}
                    onWithdrawFunction3={withdrawUnique}
                    onWithdrawFunction4={withdrawAngel}
                    onWithdrawFunction5={withdrawDragon}
                    />

      <div> 
                {getStatusMessage() && <div><p>{getStatusMessage()}</p></div>}
                
              </div>
              </div>
            </div>
            <div className="row d-flex">
            <div className="col-12 col-md-4">
              <div className="speciality-box owninside2" style={{height: '90%', flexDirection: 'column'}}>
                <div className='icondash'>
                  <img src={icon7}></img>
                </div>
                <h6 className="title"><span>YOUR APY:</span></h6>
                <p>Base APY: 10 %</p>
                <p>Common: {BasicCashemonAmount.data ? BasicCashemonAmount.data.toString() : '0'} x 0.15 %</p>
                <p>Unique: {UniqueCashemonAmount.data ? UniqueCashemonAmount.data.toString() : '0'} x 0.45 %</p>
                <p>Rare: {RareCashemonAmount.data ? RareCashemonAmount.data.toString() : '0'} x 0.80 %</p>
                <p>Legendary: {LegendaryCashemonAmount.data ? LegendaryCashemonAmount.data.toString() : '0'} x 2 %</p>
                <p>AngelBlood: {AngelBloodAmount.data ? AngelBloodAmount.data.toString() : '0'} x 1 %</p>
                <p>DragonBlood: {DragonBloodAmount.data ? DragonBloodAmount.data.toString() : '0'} x 1.5 %</p>
                <br></br>
                <p style={{fontSize: '24px'}}>Your total APY: {Number(modifiedAPY) >= 50 ? "50.00%" : `${modifiedAPY} %`}</p>
                
              </div>
            </div>
            <div className="col-12 col-md-4">  
              <div className="speciality-box owninside2" style={{height: '90%', flexDirection: 'column'}}>
                <div className='icondash'>
                  <img src={icon9}></img>
                </div>
                <h6 className="title"><span>YOUR STAKED BALANCE:</span></h6>
                <p>Staked Token: {formattedUserAmount} $SolCash</p>
                <p>(Current Value: {formattedbalancevalue} $)</p>
                <p>+</p>
                <p>Added BaseAmount: {UserBaseAmount} $SolCash</p>
                <p style={{fontSize: '24px'}}>Total: {totalstakeduser} $SolCash</p>
                <p>(Current Value: {formattedtotalstakeduservalue} $)</p>
              </div>
            </div>
            <div className="col-12 col-md-4"> 
              <div className="speciality-box owninside2" style={{height: '90%', flexDirection: 'column'}}>
                <div className='icondash'>
                  <img src={icon8}></img>
                </div>
                <h6 className="title"><span>YOUR REWARDS:</span></h6>
                <p>{formattedRewardAmount} $SolCash</p>
                <p>(Current Value: {formattedrewardvalue} $)</p>
                {rewardLoad && <div><p>Waiting for confirmation.</p></div>}
                {rewardSuccess && <div><p>Successful claimed your rewards.</p></div>}
                <button className="action-btn" onClick={() => claimreward()}><span>Claim</span></button>
              </div>
            </div>
            </div>
            <div className="col-12 col-md-12">
              <div className="speciality-box owninside2">
                <h6 className="title"><span>Stake your SolCash:</span></h6>
                <p>Stake SolCash - Earn SolCash <br></br><br></br>

                Input the number of SolCash tokens you wish to stake. Benefit from a base 10% APY and upgrade the APY with your NFT's. While there's no cost for staking or unstaking after 60 days, keep in mind that there's a 60-day lock-in period. Withdrawing before the completion of these 60 days since your last deposit will result in a 15% fee for early unstaking. You have the flexibility to unstake whenever, but an early move will cost you 15%. <br></br><br></br>

                Ensure you have enough BNB in your wallet for transaction fees. Kindly format your staking amount as follows: 1234 or 1234.43.<br></br> <br></br>

                Token Amount you want to stake:
                </p>
                <input 
                  className="input" 
                  type="number" 
                  placeholder="Enter amount" 
                  step="0.01" 
                  min="0"
                  value={userInput}
                  onChange={e => setUserInput(e.target.value)}
                />
              <div>
              {getStatusMessage2() && <div><p>{getStatusMessage2()}</p></div>}
                <button className="action-btn" onClick={approveandstake}><span>Stake</span></button>
                <button className="action-btn"  onClick={() => unstake()}><span>Unstake</span></button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
            </>
    );
}

export default DashboardNFT;