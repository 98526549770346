
import {React , useEffect} from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { bsc } from 'wagmi/chains'

import { useLocation } from 'react-router-dom';
import DashboardNFT from './pages/dashboardnft';



const chains = [ bsc ]
const projectId = 'dda29fbb02cc00c1f562f83570f22ad0'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

function App() {
    const location = useLocation(); 

    useEffect(() => {
        AOS.init({
          duration : 2000
        }); 
    }, []);

    return (
        <>
        <WagmiConfig config={wagmiConfig}>
        
 

            <Routes>
                
           <Route path='/' element={<DashboardNFT />} /> 
            </Routes>
        </WagmiConfig>
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </>
    );
}

export default App;
